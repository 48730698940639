import {useEffect,useState} from 'react';
import {API,Storage} from 'aws-amplify';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Button } from '@aws-amplify/ui-react';


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


function ReportPopup({ rep_id, description, s3Key, floodDepth, created_at, handleDelete }) {
  const [signedImageURL, setSignedImageURL] = useState('');

  useEffect(() => {
    async function loadData() {
      setSignedImageURL(await Storage.get(s3Key));
    }
    loadData();
  }, [s3Key]);
  return (
    <Popup minWidth={250} >
      {(s3Key && signedImageURL) && <a href={signedImageURL} target="_blank" rel="noreferrer"> <img src={signedImageURL} style={{width:'100%'}} alt={description} /> </a>}
      <p style={{margin:'0.5rem 0'}}>
      <b>Flood depth:</b> {floodDepth} m 
      <br/>
      <b>Description:</b> {description}<br/> 
      <b>Reported at:</b> {(new Date(created_at)).toString()}
      </p>
      <Button onClick={() => handleDelete(rep_id, created_at, s3Key)} variation="warning">Delete report</Button>
    </Popup>
  );

}


function Home(props) {
  const [allReports,setAllReports] = useState([]);

  useEffect(() => {
    async function loadData() {
      const apiName = 'floodapi';
      const path = '/reports';
      const myInit = {
        headers: {}, // OPTIONAL
        //response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        // queryStringParameters: {
        //   name: 'param' // OPTIONAL
        // }
      };

      const result = await API.get(apiName, path, myInit);
      console.log(result);
      setAllReports(result.Items);

    }
    loadData();
  }, []);

  const handleDelete = async (rep_id,created_at, s3Key) => {
    if (window.confirm('Are you sure you want to delete the report '+ rep_id+' ? \nThis action cannot be undone.'))
    {
      if (s3Key)
        await Storage.remove(s3Key);
      await API.del('floodapi','/reports/object/'+rep_id+'/'+created_at);
      console.log('successfully deleted');
      setAllReports((currentReports) => {
        return currentReports.filter((r)=>(r.id !== rep_id));
      });
    }
  }
  return (
    <div style={{height:'CALC(100vh - 60px)'}}>
      <MapContainer center={[-34.42410641908551, 150.89312392601965]} zoom={14}  style={{margin:'10px', height:'CALC(100vh - 120px)'}}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {Array.isArray(allReports) && allReports.map((rep,ind) => (
          <Marker position={[rep.location.lat, rep.location.lng]} key={ind}>
           <ReportPopup rep_id={rep.id} description={rep.text} s3Key={rep.image_url} floodDepth={rep.card_data.flood_depth} created_at={rep.created_at} handleDelete={handleDelete}/>
          </Marker>
        ))}
      </MapContainer>
      <div>
        Signed in as {props.user.attributes.email}
      </div>
    </div>
  );
}

export default Home;
