import './App.css';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Home from './Home';
import { Button } from '@aws-amplify/ui-react';

function App() {
  return (
    <Authenticator loginMechanisms={['email']}>
    {({ signOut, user }) => (
     <div className="App">
      <header className="App-header">
        <Button style={{margin:'0.5rem'}} onClick={signOut} variation="destructive">Sign out</Button>
      </header>
      <Home user={user} />
    </div>
    )}
    </Authenticator>
  );
}

export default App;
